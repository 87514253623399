import React, { useState, useEffect } from "react";
import {
    Tabs
} from 'antd';
import './index.css';
import { useLocation } from "react-router-dom";
import MyArtSpace from './myArtSpace';
import RechargeRecord from './rechargeRecord';

const UserCenter = () => {
    const location = useLocation();
    const [defaultActiveKey, setDefaultActiveKey] = useState('1');
    const onChange = (key) => {
        setDefaultActiveKey(key);
    };
    const items = [
        {
            key: '1',
            label: 'My Art Space',
            children: <MyArtSpace/>
        },
        {
            key: '2',
            label: 'Recharge Record',
            children: <RechargeRecord/>,
        }
    ];

    useEffect(() => {
        if (location?.state?.key === '2') {
            setDefaultActiveKey('2');
        } else {
            setDefaultActiveKey('1');
        }
    }, [location]);

    return <div className="user-center">
        <div className="bg"></div>
        <Tabs
            activeKey={defaultActiveKey}
            items={items}
            onChange={onChange}
            tabBarGutter={36}
        />
    </div>
}

export default UserCenter;