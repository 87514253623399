import React, { useEffect, useRef } from "react";
import {
    Modal,
    Button
} from 'antd';
import {
    CloseOutlined
} from '@ant-design/icons';
import QRCode from 'qrcode';
import './index.css';

const QRCodeModal = (props) => {
    const { getImageUrl, QROpen, setQROpen } = props;
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (getImageUrl && canvas && QROpen) {
            QRCode.toCanvas(canvas,`${getImageUrl[0]}`, { errorCorrectionLevel: 'H' }, (err) => {
                if (err) console.error(err);
            });
        }
    }, [getImageUrl, canvasRef, QROpen]);

    return <Modal
        title={'Scan the QR Code to Download Image'}
        centered
        forceRender={true}
        closable={false}
        open={QROpen}
        footer={null}
        width={'450px'}
        onCancel={()=> {setQROpen(false)}}
    >
        <Button
            onClick={()=> { setQROpen(false) }}
            type="primary"
            className="styleidaddbg-but-close"
        >
            <CloseOutlined />
        </Button>
        <canvas ref={canvasRef} className="styleidaddbg-qr-canvas"/>
    </Modal>
};

export default QRCodeModal;