import React, { useEffect, useState } from 'react';
import './index.css';
import Flexbox from './flexbox';
import { Tabs } from 'antd';
import { GetScenesInfo } from '../../api/request.js';

const List = () => {
    const [allSceneList, setAllSceneList] = useState([]);
    // const [textSceneList, setTextSceneList] = useState([]);
    // const [imageSceneList, setImageSceneList] = useState([]);
    const onChange = (key) => {
        // console.log(key);
    };
    // const items = [
    //     {
    //         key: '1',
    //         label: 'All Models',
    //         children:
    //             <Flexbox
    //                 key="all-models"
    //                 sceneList={allSceneList}
    //                 setSceneList={setAllSceneList}
    //                 module="all-models"
    //             />
    //     },
    //     {
    //         key: '2',
    //         label: 'Text To Image',
    //         children:
    //             <Flexbox
    //                 key="text-to-image"
    //                 sceneList={textSceneList}
    //                 setSceneList={setTextSceneList}
    //                 module="text-to-image"
    //             />
    //     },
    //     {
    //         key: '3',
    //         label: 'Image To Image',
    //         children:
    //             <Flexbox
    //                 key="image-to-image"
    //                 sceneList={imageSceneList}
    //                 setSceneList={setImageSceneList}
    //                 module="image-to-image"
    //             />
    //     }
    // ];

    useEffect(() => {
        (async() => {
            const getScenesList = await GetScenesInfo();
            if (getScenesList.length > 0) {
                const newList = JSON.parse(JSON.stringify(getScenesList));
                for (let i = 0; i < newList.length; i++) {
                    newList[i] = {
                        ...newList[i],
                        loaded: false,
                        error: false
                    };
                }
                newList.sort((a, b) => a.scenes[0].order - b.scenes[0].order);
                setAllSceneList(newList);
                // setTextSceneList(newList);
                // setImageSceneList(newList);
            }
        })()
    }, []);

    return (
        <div className='list'>
            {/* <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
                tabBarGutter={36}
            /> */}
            <Flexbox
                key="all-models"
                sceneList={allSceneList}
                setSceneList={setAllSceneList}
                module="all-models"
            />
        </div>
    );
};

export default List;