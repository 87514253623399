import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import List from '../pages/list';
import Pricing from '../pages/pricing';
import ContactUs from '../pages/contactus';

import FaceSwipe from '../pages/faceSwipe';
import TextStyleImage from '../pages/textStyleImage';
import TextToImage from '../pages/textToImage';
import PortraitMaster from '../pages/portraitMaster';
import SuperfractionRepair from '../pages/superfractionRepair';
import ImageToImage from '../pages/imageToImage';
import FaceIdToImage from '../pages/faceIdToImage';
import FaceIdLiteToImage from '../pages/faceIdLiteToImage';
import StyleIdToImage from '../pages/styleIdToImage';
import PoseFaceImage from '../pages/poseFaceImage';
import DoublePoseFaceImage from '../pages/doublePoseFaceImage';
import RemoveBg from '../pages/removeBg';
import UserCenter from '../pages/userCenter';

import Privacy from '../pages/privacy';
import StyleIdAddBg from '../pages/styleIdAddBg';

export default function AppRouter() {

    return (
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route path="/home" element={<Home />} />
            <Route path="/list" element={<List/>} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/user_center" element={<UserCenter />} />

            <Route path="/face_change" element={<FaceSwipe />}/>
            <Route path="/text_style_image" element={<TextStyleImage />}/>
            <Route path="/superfraction_repair" element={<SuperfractionRepair />} />
            <Route path="/image_to_image" element={<ImageToImage />} />
            <Route path="/faceId_to_image" element={<FaceIdToImage />} /> 
            <Route path="/faceIdLite_to_image" element={<FaceIdLiteToImage />} /> 
            <Route path="/styleId_to_image" element={<StyleIdToImage />}/>
            <Route path="/styleId_add_bg" element={<StyleIdAddBg />}/>
            <Route path="/text_to_image" element={<TextToImage />}/>
            <Route path="/portrait_master" element={<PortraitMaster />}/>
            <Route path="/pose_face_image" element={<PoseFaceImage />}/>
            <Route path="/double_pose_face_image" element={<DoublePoseFaceImage />}/>
            <Route path="/remove_bg" element={<RemoveBg />}/>

            <Route path="/privacy" element={<Privacy />} />
        </Routes>
    );
};