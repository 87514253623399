import React, { useState, useEffect, useRef } from "react";
import './index.css';
import { UserOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Avatar,
    Radio,
    Space,
    Menu,
    Button,
    Dropdown,
    Modal,
    Divider,
    Drawer
} from 'antd';
import { Link, useLocation, useNavigate  } from "react-router-dom";
import topRightLogo from '../../images/brand@2x.png';
import titlelogo from '../../images/titlelogo.png';
import userLogo from '../../images/userLogo.png';
import move_menu from '../../images/move_menu.png';
import { useTranslation } from 'react-i18next';
import LoginModal from './loginModal';
import SignupModal from './signupModal';
import { useTasks, useTasksDispatch } from '../../TasksContext';
import {
    GetUserInfo,
    LogOut
} from '../../api/request.js';

const userLanguage = navigator.language;

const MyHeader = () => {
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const [current, setCurrent] = useState('home');
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('code');

    const onChange = (e) => {
        try {
            i18n.changeLanguage(e.target.value);
        } catch (error) {}
    };
    const onClick = (e) => {
        setCurrent(e.key);
        navigate ('/' + e.key)
    };
    const items = [
        {
            label: t('header.home'),
            key: 'home',
        },
        {
            label: t('header.list'),
            key: 'list',
        },
        {
            label: t('header.pricing'),
            key: 'pricing',
        },
        {
            label: t('header.contactus'),
            key: 'contactus'
        },
    ];
    const useItems = [
        {
            key: '1',
            label: 'Profile',
        },
        {
            key: '2',
            label: 'Recharge Record'
        },
        {
            key: '3',
            label: 'My Gallery'
        },
        {
            key: '4',
            label: 'Log Out',
        },
    ];
    const loginClick = async () => {
        // const code = '161d338d-a723-47b8-9c14-3f9e79e6605d.298685ff-e4f5-4215-8fd4-37a7df21468f.cf626a9b-c860-449b-a0d8-d0bb358e5866'
        // const getUserInfo = await GetUserInfo({ code });
        // if (getUserInfo.code === 1000) {
        //     dispatch({
        //         type: 'login',
        //         data: {
        //             userid: getUserInfo?.data?.userinfo.userid,
        //             name: getUserInfo.data.userinfo.name,
        //             token: getUserInfo.data.tokens.access_token,
        //             expires_in: getUserInfo.data.tokens.expires_in,
        //             email: getUserInfo.data.userinfo.email,
        //             islogin: Boolean(getUserInfo.data.userinfo.islogin)
        //         }
        //     });
        // }
        window.location.href = 'https://test.tensorai.tensorslab.com/realms/tensorai/protocol/openid-connect/auth?client_id=goodapp&response_type=code&redirect_uri=https://tensorai.tensorslab.com&scope=openid&state=randomcode';
    }
    const signupClick = () => {
        // setIsLogin(true);
        dispatch({
            type: 'open_signup_modal',
        });
    }
    const useOnChange = async ({key}) => {
        switch (key) {
            case '1':
                
                break;
            case '2':
                navigate('/user_center', { state: {key: '2'} });
                break;
            case '3':
                navigate('/user_center', { state: {key: '3'} });
                break;
            case '4':
                //TODO
                const getLogOut = await LogOut({
                    data: {
                        uid: tasks[3].userid
                    },
                    token: tasks[3].token
                });
                if (getLogOut.code === 1000) {
                    dispatch({
                        type: 'log_out',
                        data: tasks[3].userid
                    });
                }
                break;
        
            default:
                break;
        }
    }
    useEffect(() => {
        try {
            if (location.pathname) {
                const parts = location.pathname.split("/");
                if (parts[1]) {
                    setCurrent(parts[1])
                } else {
                    setCurrent('home')
                }
            }
        } catch (error) { }
    }, [location]);
    useEffect(() => {
        try {
            (async() => {
                if (paramValue) {
                    const getUserInfo = await GetUserInfo({ paramValue });
                    if (getUserInfo.code === 1000) {
                        dispatch({
                            type: 'login',
                            data: {
                                userid: getUserInfo?.data?.userinfo.userid,
                                name: getUserInfo.data.userinfo.name,
                                token: getUserInfo.data.tokens.access_token,
                                expires_in: getUserInfo.data.tokens.expires_in,
                                email: getUserInfo.data.userinfo.email,
                                islogin: Boolean(getUserInfo.data.userinfo.islogin)
                            }
                        });
                    }
                }
            })()
        } catch (error) {}
    }, [dispatch, paramValue]);
    const onClose = () => {
        setOpen(false);
    };
    const showMenuClick = () => {
        setOpen(true);
    };

    console.log('tasks', tasks);

    return <div className="heart">
        <div className="menu">
            <img className="img" src={titlelogo} alt="" />
            {
                tasks[2].windowWidth > 768 ?
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                /> :
                <img src={move_menu} alt="" onClick={showMenuClick}/>
            }
        </div>
        {
            tasks[2].windowWidth > 768 ?
                <div className="userAndMenu">
                    {/* <Radio.Group onChange={onChange} defaultValue={ userLanguage === 'zh-CN' ? 'zh' : 'en'} className="i18n-buts">
                        <Radio.Button value="en">English</Radio.Button>
                        <Radio.Button value="zh">中文</Radio.Button>
                    </Radio.Group> */}
                    {/* <Avatar className="hover" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} size={40} /> */}
                    {
                        tasks[3].islogin ?
                            <Dropdown
                                menu={{ items: useItems, onClick: useOnChange }}
                                placement="bottomRight"
                            >
                                <div className="user ant-dropdown-open">
                                    <Avatar className="img">
                                        {tasks[3].name.split(' ')[1] ? tasks[3].name.split(' ')[1].charAt(0) : 'A'}
                                    </Avatar>
                                    <div className="user-name">{tasks[3].name}</div>
                                </div>
                            </Dropdown> :
                            <>
                                <Button
                                    type="link"
                                    className="login"
                                    onClick={loginClick}
                                >{'Login'}</Button>
                                {/* <Button
                                    type="primary"
                                    shape="round"
                                    className="signup"
                                    onClick={signupClick}
                                >{'Signup'}</Button> */}
                            </>
                    }
                </div> :
                <Drawer
                    title={<div className="header-menu">
                        <img className="img" src={titlelogo} alt="" />
                        <Button icon={<CloseOutlined />} onClick={onClose} type="text"></Button>
                    </div>}
                    placement='left'
                    closable={false}
                    onClose={onClose}
                    open={open}
                    key='header_menu'
                    width={280}
                    className="header-drawer"
                >
                    <Menu
                        onClick={onClick}
                        selectedKeys={[current]}
                        mode="vertical"
                        items={items}
                    />
                    <Divider />
                    <div className="userAndMenu">
                    {
                        tasks[3].islogin ?
                            <Dropdown
                                menu={{ items: useItems, onClick: useOnChange }}
                                placement="bottomRight"
                            >
                                <div className="user ant-dropdown-open">
                                    <Avatar className="img">
                                        {tasks[3].name.split(' ')[1] ? tasks[3].name.split(' ')[1].charAt(0) : 'AI'}
                                    </Avatar>
                                    <div className="user-name">{tasks[3].name}</div>
                                </div>
                            </Dropdown> :
                            <>
                                <Button
                                    type="link"
                                    className="login"
                                    onClick={loginClick}
                                >{'Login'}</Button>
                                {/* <Button
                                    type="primary"
                                    shape="round"
                                    className="signup"
                                    onClick={signupClick}
                                >{'Signup'}</Button> */}
                            </>
                    }
                </div>
                </Drawer>
        }
        <LoginModal />
        <SignupModal/>
    </div>
}

export default MyHeader;