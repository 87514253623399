import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Row,
    Col
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './index.css';
import contact_us_bg from '../../images/contact_us_bg.png';

const ContactUs = () => {

    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('values', values);
    }

    return (
        <div className='contact-us'>
            <img src={contact_us_bg} alt="" />
            <Form
                form={form}
                layout="vertical"
                // initialValues={{}}
                onFinish={onFinish}
                className='form'
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            label={<div className='label'>{"Name"}</div>}
                            name='name'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input className='input' placeholder="input placeholder" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<div className='label'>{"Email"}</div>}
                            name='email'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input className='input' placeholder="input placeholder" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={<div className='label'>{"Message"}</div>}
                            name="message"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea className='text-area' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className='but'
                            >Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ContactUs;