import React, { useRef, useState, useEffect } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip,
    Modal,
    Cascader
} from 'antd';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import {
    QuestionCircleOutlined,
    InboxOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import {
    DoublePoseFaceAPI,
} from '../../api/request.js';
import TextAreaInput from '../../components/textAreaInput/index.jsx';
import PubInput from '../../components/pubInput/index.jsx';
import SelectInput from '../../components/selectInput/index.jsx';
import 'react-resizable/css/styles.css';
import { SCENE } from '../../constants/index.js';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const DoublePoseFaceImage = (props) => {
    const { config } = props;
    
    //modal
    const previewImageRef = useRef(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [disabled, setDisabled] = useState(true);
    const handleCancel = () => setPreviewOpen(false);
    const draggleRef = useRef(null);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(400);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });
    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x - targetRect.width/2,
            right: clientWidth - (targetRect.right - uiData.x) + targetRect.width/2,
            top: -targetRect.top + uiData.y - targetRect.height/2,
            bottom: clientHeight - (targetRect.bottom - uiData.y) + targetRect.height/2,
        });
    };
    const handleResize = (_event, { size }) => {
        const { width, height } = size;
        if (width >= 400) {
            setWidth(width);
        } else {
            setWidth(400);
        }
        if (height >= 400) {
            setHeight(height);
        } else {
            setHeight(400);
        }
    };
    useEffect(() => {
        if (previewOpen) {
            setWidth(400);
            setHeight(400);
        }
    }, [previewOpen]);

    //form
    const [getImageUrl, setGetImageUrl] = useState('');
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const formRef = useRef(null);
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };
    const handleFormSubmit = async (values) => {
        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const formData = new FormData();
            formData.append('sourceImage', values.left_face[0].originFileObj);
            formData.append('sourceImage', values.right_face[0].originFileObj);
            formData.append('poseImage', values.pose[0].originFileObj);
            formData.append('category', values.scene[0]);
            formData.append('scene', values.scene[1]);
            formData.append('type', values.type);
            formData.append('positivePrompt', values.positivePrompt);
            if (config?.debug) {
                formData.append('negativePrompt', values.negativePrompt);
            }
            formData.append('num', 1);
            formData.append('seed', values.seed);
          
            const res = await DoublePoseFaceAPI({formData: formData, ip: config.ip, token: config.token});
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('生成失败，请重试');
            };
        } catch (error) {
            message.error('生成失败，请重试');
        };
        setDisabledSubmit(false);
    };
    const onChangeScene = (value) => {
        console.log(value);
    };

    //Upload
    const [fileListLeftFace, setFileListLeftFace] = useState([]);
    const [fileListRightFace, setFileListRightFace] = useState([]);
    const [fileListPose, setFileListPose] = useState([]);
    const handlePreview = async (file) => {
        try {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        } catch (error) {}
    };
    const handleChange = ({ newFileList, img }) => {
        try {
            if (img === 'left_face') {
                setFileListLeftFace(newFileList);
            } else if (img === 'right_face') {
                setFileListRightFace(newFileList);
            } else if (img === 'pose') {
                setFileListPose(newFileList);
            }
        } catch (error) {}
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <InboxOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload =async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('您只能上传图片文件!');
                return Upload.LIST_IGNORE;
            }
            const isLt2M = file.size / 1024 / 1024 < 6;
            if (!isLt2M) {
                message.error('图像必须小于6MB!');
                return Upload.LIST_IGNORE;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('出错了，可以稍后刷新再试!');
            return Upload.LIST_IGNORE;
        }
    };

    //download
    const handleDownload = () => {
        const imageUrl = getImageUrl[0];

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<div className="double-pose-face-image">
        <div className="double-pose-face-image-box">
            <div className="prompt">
                <Form
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                    ref={formRef}
                    initialValues={{style: 'none', type: 3, scene: ["50s Retro Ads", "50s Coca Cola Ad"]}}
                >
                    <div className="title">
                        <span>{'Source Image(Upload two image)'}</span>
                        <Tooltip placement="top" title={'上传两张目标人物图片'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <Form.Item className="two-face-box">
                        <Form.Item
                            name="left_face"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: '请选择图片' }]}
                            className="two-face"
                        >
                            <Upload
                                action="/#"
                                listType="picture-card"
                                fileList={fileListLeftFace}
                                onPreview={handlePreview}
                                onChange={(newFileList) => handleChange({ newFileList, img: 'left_face' })}

                                name="left_face"
                                maxCount={1}
                                beforeUpload={beforeUpload}
                                className='upload-source'
                                disabled={disabledSubmit}
                            >
                                {fileListLeftFace.fileList && fileListLeftFace.fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            name="right_face"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: '请选择图片' }]}
                            className="two-face"
                        >
                            <Upload
                                action="/#"
                                listType="picture-card"
                                fileList={fileListRightFace}
                                onPreview={handlePreview}
                                onChange={(newFileList) => handleChange({ newFileList, img: 'right_face' })}

                                name="right_face"
                                maxCount={1}
                                beforeUpload={beforeUpload}
                                className='upload-source'
                                disabled={disabledSubmit}
                            >
                                {fileListRightFace.fileList && fileListRightFace.fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Form.Item>
                    <div className="title">
                        <span>Pose Image</span>
                        <Tooltip placement="top" title={'上传姿势图片'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <Form.Item
                        name="pose"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: '请选择图片' }]}
                    >
                        <Upload
                            action="/#"
                            listType="picture-card"
                            fileList={fileListPose}
                            onPreview={handlePreview}
                            onChange={(newFileList) => handleChange({ newFileList, img: 'pose' })}

                            name="pose"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            className='upload-source'
                            disabled={disabledSubmit}
                        >
                            {fileListPose.fileList && fileListPose.fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                    <Modal
                        open={previewOpen}
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    cursor: 'move',
                                }}
                                onMouseOver={() => {
                                    if (disabled) {
                                        setDisabled(false);
                                    }
                                }}
                                onMouseOut={() => {
                                    setDisabled(true);
                                }}
                            >
                                {previewTitle}
                                <div
                                    style={{
                                        width: previewImageRef?.current?.offsetWidth || 400,
                                        height: previewImageRef?.current?.offsetHeight || 400,
                                        position: 'absolute',
                                        top: '32px',
                                        right: 0,
                                        zIndex: 999
                                    }}
                                >
                                </div>
                            </div>
                        }
                        footer={null}
                        onCancel={handleCancel}
                        mask={false}
                        modalRender={(modal) => (
                            <Draggable
                                disabled={disabled}
                                bounds={bounds}
                                nodeRef={draggleRef}
                                onStart={(event, uiData) => onStart(event, uiData)}
                            >
                                <div ref={draggleRef}>{modal}</div>
                            </Draggable>
                        )}
                        width={width}
                        className="preview"
                    >
                        <Resizable
                            width={width}
                            height={height}
                            onResize={handleResize}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <img
                                    alt="example"
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        verticalAlign: 'middle',
                                        userDrag: 'none',
                                        WebkitUserDrag: 'none',
                                        userSelect: 'none',
                                        WebkitUserSelect: 'none',
                                        MozUserSelect: 'none',
                                        MsUserSelect: 'none'
                                    }}
                                    src={previewImage}
                                    ref={previewImageRef}
                                />
                            </div>
                        </Resizable>
                    </Modal>
                    <div className="title">
                        <span>Scene</span>
                        <Tooltip placement="top" title={'选择场景'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <Form.Item
                        name="scene"
                        className="cascader"
                    >
                        <Cascader
                            allowClear={false}
                            options={SCENE}
                            className="cascader-input"
                            placeholder="Please select"
                            disabled={disabledSubmit}
                        />
                    </Form.Item>
                    <SelectInput
                        title={"Type"}
                        name={'type'}
                        // int, 1-单男（暂不支持）, 2-单女（暂不支持）, 3-男左女右, 4-女左男右, 5-双男, 6-双女
                        options={[
                            // {
                            //   value: 1,
                            //   label: '单男',
                            // },
                            // {
                            //   value: 2,
                            //   label: '单女',
                            // },
                            {
                              value: 3,
                              label: 'A man and a woman',
                            },
                            {
                              value: 4,
                              label: 'Two men',
                            },
                            {
                              value: 5,
                              label: 'Two women',
                            },
                        ]}
                        disabled={disabledSubmit}
                    />
                    <TextAreaInput
                        title="Prompt"
                        iconTitle="生成图片的关键提示词"
                        placeholder="Please input Prompt!"
                        name="positivePrompt"
                        disabled={disabledSubmit}
                        height="50px"
                    />
                    {
                        config?.debug ?
                        <TextAreaInput
                            title="Negative Prompt"
                            iconTitle="生成图片的负向提示词"
                            placeholder="Please input negativePrompt!"
                            name="negativePrompt"
                            disabled={disabledSubmit}
                            height="50px"
                        /> : <></>
                    }
                    <div style={{ display: 'block' }}>
                        <PubInput
                            title='Seed'
                            name='seed'
                            typr='number'
                            disabled={disabledSubmit}
                            iconTitle='种子可以是随机也可以是固定，默认不输入就是随机'
                        />
                    </div>
                </Form>
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    Generate
                </Button>
            </div>
            <div className="output-image">
                <div className="output-image-bfc">
                    { getImageUrl ? <Image src={getImageUrl} className="img"/> : <></> }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >正在生成中，请稍等...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl ?
                        <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
                            <Button
                                onClick={handleDownload}
                                className="but"
                                icon={<DownloadOutlined />}
                            ></Button>
                        </Tooltip> : <></>
                }
            </div>
        </div>
    </div>
    )
};
export default DoublePoseFaceImage;