import React, { useRef, useEffect, useState } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image as AntdImage,
    Tooltip
} from 'antd';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    RollbackOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { RemoveBgAPI } from '../../api/request.js';
import EditImage from './editImage';


const getBase64 = (img, callback) => {
    try {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    } catch (error) {}
};

const RemoveBg = (props) => {
    const { config } = props;
    const [imageUrl, setImageUrl] = React.useState('');

    const [getImageUrl, setGetImageUrl] = React.useState('');
    const [disabledSubmit, setDisabledSubmit] = React.useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editImageShow, setEditImageShow] = useState(false);
    const [drawingHistory, setDrawingHistory] = useState([]);
    const [resultSrc, setResultSrc] = useState('');
    const [resultSpritesSrc, setResultSpritesSrc] = useState('');
    const handleEdit = () => {
        setEditImageShow(true);
        setIsModalOpen(true);
    }
    useEffect(() => {
        setIsModalOpen(false);
        setEditImageShow(false);
        setDrawingHistory([]);
        setResultSrc('');
        setResultSpritesSrc('');
    }, [imageUrl]);

    const formRef = useRef(null);
    const navigate = useNavigate();
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload =async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('您只能上传图片文件!');
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 6;
            if (!isLt2M) {
                message.error('图像必须小于6MB!');
                return false;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('出错了，可以稍后刷新再试!');
            return false;
        }
    };
    const handleChange = (info) => {
        if (info?.fileList?.length > 0) {
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
        }
    };
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };
    const handleFormSubmit = async (values) => {
        console.log('resultSrc', resultSrc);
        console.log('drawingHistory', drawingHistory);
        return
        if (drawingHistory.length > 0) {
            setDisabledSubmit(true);
            try {
                setGetImageUrl('');
                const formData = new FormData();
                formData.append('targetImage', values.pose[0].originFileObj);
                formData.append('sourceImage', values.face[0].originFileObj);
                const res = await RemoveBgAPI({formData: formData, ip: config.ip, token: config.token});
                if (res.data.url && res.code === 1000) {
                    setGetImageUrl(res.data.url);
                } else {
                    message.error('生成失败，请重试');
                };
            } catch (error) {
                message.error('生成失败，请重试');
            };
            setDisabledSubmit(false);
        } else {
            message.error('图片未编辑！');
        }
    };
    const handleDownload = () => {
        const imageUrl = getImageUrl;
    
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<div className="remove-bg">
        {/* <div className="remove-bg-header">
            <Button shape="circle" icon={<RollbackOutlined />} onClick={() => navigate('/home')}/>
            <div>Playground</div>
        </div> */}
        <div className="remove-bg-box">
            <div className="prompt">
                <div className="title">
                    <span>Prompt</span>
                    <Tooltip placement="top" title={'上传图片'} arrow={true}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </div>
                <Form
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                    ref={formRef}
                    layout="inline"
                >
                    
                    <Form.Item
                        name="pose"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: '请选择图片' }]}
                    >
                        <Upload.Dragger
                            name="pose"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            showUploadList={{
                                showDownloadIcon: true,
                                downloadIcon: 'Download',
                                showRemoveIcon: true,
                                removeIcon: <DeleteOutlined onClick={(e) => { console.log(e, 'custom removeIcon event'); setImageUrl(''); }} />,
                            }}
                            className='upload-pose'
                            disabled={disabledSubmit}
                        >
                            {imageUrl ? (
                                    <img src={imageUrl} alt="avatar" />
                                ) : (<>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-hint">单击或拖动文件到此区域</p>
                                </>
                            )}
                            {resultSpritesSrc ?
                                <img src={resultSpritesSrc} alt="提取结果" style={{ padding: '16px' }} /> : <></>
                            }
                        </Upload.Dragger>
                    </Form.Item>
                    {
                        imageUrl ?
                            <Button
                                className="edit-but"
                                onClick={handleEdit}
                            >
                                Edit
                            </Button> : <></>
                    }
                </Form>
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    Generate
                </Button>
            </div>
            <div className="output-image">
                <div className="output-image-bfc">
                    { getImageUrl ? <AntdImage src={getImageUrl} className="img"/> : <></> }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >正在生成中，请稍等...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl ?
                        <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
                            <Button
                                onClick={handleDownload}
                                className="but"
                                icon={<DownloadOutlined />}
                            ></Button>
                        </Tooltip> : <></>
                }
            </div>
            {
                editImageShow ?
                    <EditImage
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        imageUrl={imageUrl}
                        drawingHistory={drawingHistory}
                        setDrawingHistory={setDrawingHistory}
                        resultSrc={resultSrc}
                        setResultSrc={setResultSrc}
                        resultSpritesSrc={resultSpritesSrc}
                        setResultSpritesSrc={setResultSpritesSrc}
                        setEditImageShow={setEditImageShow}
                    /> : <></>
            }
        </div>
    </div>
    )
};
export default RemoveBg;


// const RemoveBg = (props) => {
//     const { config } = props;
//     const [imageUrl, setImageUrl] = React.useState('');
//     const [imageUrl2, setImageUrl2] = React.useState('');

//     const [getImageUrl, setGetImageUrl] = React.useState('');
//     const [disabledSubmit, setDisabledSubmit] = React.useState(false);

//     const canvasRef = useRef(null);
//     const myCanvasObjectRef = useRef(null);
//     useEffect(() => {
//         const canvas = new fabric.Canvas(canvasRef.current, {
//             isDrawingMode: true
//         });
//         fabric.Object.prototype.transparentCorners = false;
//         if (canvas) {
//             canvas.freeDrawingBrush.width = 5;
//         }
//         console.log('canvas', canvas);
//         const myObject = {
//             clearCanvas: () => {
//                 canvas.clear();
//             },
//             drawingColorChange: (event) => {
//                 const brush = canvas.freeDrawingBrush;
//                 brush.color = event.target.value;
//                 if (brush.getPatternSrc) {
//                     brush.source = brush.getPatternSrc.call(brush);
//                 }
//             },
//             editBrushWidth: (px) => {
//                 const brush = canvas.freeDrawingBrush;
//                 brush.width = px
//             },
//             editCanvasWidthHeight: ({ width, height }) => {
//                 canvas.width = width;
//                 canvas.height = height;
//             },
//         };
      
//         myCanvasObjectRef.current = myObject;

//         return () => {
//             canvas.dispose();
//         };
//     }, []);
//     const onClearCanvas = () => {
//         myCanvasObjectRef.current.clearCanvas()
//     };
//     const handleBrushSizeChange = (event) => {
//         if (myCanvasObjectRef) {
//             const size = parseInt(event.target.value);
//             if (!isNaN(size)) {
//                 myCanvasObjectRef.current.editBrushWidth(size)
//             }
//         }
//     };
//     const handleImageUpload = (event) => {
//         const file = event.target.files[0];
//         const reader = new FileReader();
    
//         reader.onload = (e) => {
//             const imgData = e.target.result;
        
//             fabric.Image.fromURL(imgData, (image) => {
//                 onClearCanvas();
//                 console.log(image.width, image.height);
//                 myCanvasObjectRef.current.editCanvasWidthHeight({ width: image.width, height: image.height })
        
//                 // canvas.current.setBackgroundImage(image, canvas.current.renderAll.bind(canvas.current));
//             }, { crossOrigin: 'anonymous' });
//         };
//         setImageUrl2(file);
//         console.log('file', file);
//         reader.readAsDataURL(file);
//     };

//     const formRef = useRef(null);
//     const navigate = useNavigate();
//     const normFile = (e) => {
//         if (Array.isArray(e)) {
//             return e;
//         }
//         return e?.fileList;
//     };
//     const beforeUpload =async (file) => {
//         try {
//             const isJpgOrPng = file.type.startsWith('image');
//             if (!isJpgOrPng) {
//                 message.error('您只能上传图片文件!');
//                 return false;
//             }
//             const isLt2M = file.size / 1024 / 1024 < 6;
//             if (!isLt2M) {
//                 message.error('图像必须小于6MB!');
//                 return false;
//             }

//             return isLt2M && isJpgOrPng;
//         } catch (error) {
//             message.error('出错了，可以稍后刷新再试!');
//             return false;
//         }
//     };
//     const handleChange = (info) => {
//         if (info?.fileList?.length > 0) {
//             getBase64(info.file.originFileObj, (url) => {
//                 setImageUrl(url);
//             });
//         }
//     };
//     const handleSubmit = () => {
//         if (formRef.current) {
//             formRef.current.submit();
//         }
//     };
//     const handleFormSubmit = async (values) => {
//         setDisabledSubmit(true);
//         try {
//             setGetImageUrl('');
//             const formData = new FormData();
//             formData.append('targetImage', values.pose[0].originFileObj);
//             formData.append('sourceImage', values.face[0].originFileObj);
//             const res = await RemoveBgAPI({formData: formData, ip: config.ip, token: config.token});
//             if (res.data.url && res.code === 1000) {
//                 setGetImageUrl(res.data.url);
//             } else {
//                 message.error('生成失败，请重试');
//             };
//         } catch (error) {
//             message.error('生成失败，请重试');
//         };
//         setDisabledSubmit(false);
//     };
//     const handleDownload = () => {
//         const imageUrl = getImageUrl;
    
//         const link = document.createElement('a');
//         link.href = imageUrl;
//         link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     };

//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     return (<div className="remove-bg">
//         {/* <div className="remove-bg-header">
//             <Button shape="circle" icon={<RollbackOutlined />} onClick={() => navigate('/home')}/>
//             <div>Playground</div>
//         </div> */}
//         <div className="remove-bg-box">
//             <div className="prompt">
//                 <div className="title">
//                     <span>Prompt</span>
//                     <Tooltip placement="top" title={'上传图片'} arrow={true}>
//                         <QuestionCircleOutlined />
//                     </Tooltip>
//                 </div>
//                 <Form
//                     name="validate_other"
//                     onFinish={handleFormSubmit}
//                     className='form'
//                     ref={formRef}
//                     layout="inline"
//                 >
//                     <Form.Item
//                         name="pose"
//                         valuePropName="fileList"
//                         getValueFromEvent={normFile}
//                         noStyle
//                         rules={[{ required: true, message: '请选择图片' }]}
//                     >
//                         <Upload.Dragger
//                             name="pose"
//                             maxCount={1}
//                             beforeUpload={beforeUpload}
//                             onChange={handleChange}
//                             showUploadList={{
//                                 showDownloadIcon: true,
//                                 downloadIcon: 'Download',
//                                 showRemoveIcon: true,
//                                 removeIcon: <DeleteOutlined onClick={(e) => { console.log(e, 'custom removeIcon event'); setImageUrl(''); }} />,
//                             }}
//                             className='upload-pose'
//                             disabled={disabledSubmit}
//                         >
//                             {imageUrl ? (
//                                 <img src={imageUrl} alt="avatar" />
//                             ) : (<>
//                                 <p className="ant-upload-drag-icon">
//                                     <InboxOutlined />
//                                 </p>
//                                 <p className="ant-upload-hint">单击或拖动文件到此区域</p>
//                             </>
//                         )}
//                         </Upload.Dragger>
//                     </Form.Item>
//                     <div>
//                         <input type="file" onChange={handleImageUpload} />
//                         {
//                             imageUrl2 ? <img src={imageUrl2[0]} alt="" /> : <></>
//                         }
//                         <canvas ref={canvasRef} style={{ maxWidth: '300px', maxHeight: '300px' }}></canvas>
//                         <Button
//                             type="primary"
//                             shape="round"
//                             size="large"
//                             onClick={onClearCanvas}
//                         >
//                             Clear Canvas
//                         </Button>
//                         <input type="range" min="1" max="50" defaultValue={5} onChange={handleBrushSizeChange} />
//                     </div>
//                 </Form>
//                 <Button
//                     type="primary"
//                     shape="round"
//                     size="large"
//                     className="but"
//                     onClick={handleSubmit}
//                     disabled={disabledSubmit}
//                 >
//                     Generate
//                 </Button>
//             </div>
//             <div className="output-image">
//                 <div className="output-image-bfc">
//                     { getImageUrl ? <AntdImage src={getImageUrl} className="img"/> : <></> }
//                     {
//                         disabledSubmit ?
//                         <div className="loading-box">
//                             <Spin size="large" className="loading" />
//                             <div className="text" >正在生成中，请稍等...</div>
//                         </div> : <></>
//                     }
//                 </div>
//                 {
//                     getImageUrl ?
//                         <Tooltip placement="bottom" title={'下载图片'} arrow={true}>
//                             <Button
//                                 onClick={handleDownload}
//                                 className="but"
//                                 icon={<DownloadOutlined />}
//                             ></Button>
//                         </Tooltip> : <></>
//                 }
//             </div>
//         </div>
//     </div>
//     )
// };
// export default RemoveBg;

// const RemoveBg = () => {
//     const [image, setImage] = useState(null);
//     const getStage = (stage) => {
//         console.log('stage', stage);
//     }
//     const handleExport = (exportedImage) => {
//         setImage(exportedImage); // 导出图片后，更新状态
//     };
//     console.log('image', image)
//     return <div>
//         <ReactImgEditor
//             toolbar={
//                 {items: ['pen', 'eraser', 'download']}
//             }
//             onExport={handleExport}
//             getStage={getStage}
//             crossOrigin={true}
//             src="https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics0.baidu.com%2Ffeed%2F7acb0a46f21fbe09a95a6836f20a403d8744ad01.jpeg%40f_auto%3Ftoken%3Deeacf8a4443001e2d467e418b7d98955&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1720803600&t=ca4fb47904aa7fa70e233f6ec1f02ca8"
//             width={1000}
//             height={1000}
//         />
//     </div>
// }
// export default RemoveBg;

// const src = 'https://images.pexels.com/photos/2187304/pexels-photo-2187304.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

// export default function RemoveBg() {
//     const imgRef = useRef(null);
//     const canvasRef = useRef(null);
//     const [drawing, setDrawing] = useState(false);
//     const [drawingHistory, setDrawingHistory] = useState([]);
//     const [resultSrc, setResultSrc] = useState('');

//     const startDrawing = (event) => {
//         setDrawing(true);
//         const ctx = canvasRef.current.getContext('2d');
//         ctx.beginPath();

//         const { offsetX, offsetY } = event.nativeEvent;
//         ctx.moveTo(offsetX, offsetY);
//     };

//     const draw = (event) => {
//         if (!drawing) return;

//         const ctx = canvasRef.current.getContext('2d');
//         ctx.lineWidth = 5;
//         ctx.strokeStyle = 'white';

//         const { offsetX, offsetY } = event.nativeEvent;
//         ctx.lineTo(offsetX, offsetY);
//         ctx.stroke();
//     };

//     const stopDrawing = () => {
//         setDrawing(false);

//         const ctx = canvasRef.current.getContext('2d');
//         ctx.closePath();
//         setDrawingHistory([...drawingHistory, canvasRef.current.toDataURL()]);
//     };

//     const canvasToImage = () => {
//         const newCanvas = document.createElement('canvas');
//         const newContext = newCanvas.getContext('2d');
//         newCanvas.width = canvasRef.current.width;
//         newCanvas.height = canvasRef.current.height;

//         newContext.fillStyle = 'black';
//         newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
//         newContext.drawImage(canvasRef.current, 0, 0);

//         return newCanvas.toDataURL('image/png');
//     };

//     const undoLastDraw = () => {
//         if (drawingHistory.length > 0) {
//             const newHistory = drawingHistory.slice(0, -1);
//             setDrawingHistory(newHistory);
//             clearCanvas();
//             redrawHistory(newHistory);
//         }
//     };

//     const clearCanvas = () => {
//         const ctx = canvasRef.current.getContext('2d');
//         ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
//     };

//     const redrawHistory = (history) => {

//         const ctx = canvasRef.current.getContext('2d');
//         const dataURL = history[history.length - 1];
//         if (dataURL) {
//             const img = new Image();
//             img.src = dataURL;

//             img.onload = () => {
//                 ctx.drawImage(img, 0, 0);
//             };
//         }
//     };

//     const clearAll = () => {
//         setDrawingHistory([]);
//         clearCanvas();
//     };

//     const extraction = () => {
//         setResultSrc(canvasToImage());
//     };

//     useEffect(() => {
//         const setCanvas = () => {
//             if (canvasRef.current && imgRef.current) {
//                 canvasRef.current.width = imgRef.current.width;
//                 canvasRef.current.height = imgRef.current.height;
//             }
//         };
//         setCanvas();
//     }, []);

//     return (
//         <div>
//             <p>你可以在下图中做任意涂鸦：</p>
//             <div className="main">
//                 <div className="image" onContextMenu={(e) => e.preventDefault()}>
//                 <img ref={imgRef} src={src} alt="可涂鸦的图像" />
//                 <canvas
//                     ref={canvasRef}
//                     onMouseDown={startDrawing}
//                     onMouseMove={draw}
//                     onMouseUp={stopDrawing}
//                 />
//                 </div>
//             </div>
//             <div className="actions">
//                 <button onClick={undoLastDraw}>撤回</button>
//                 <button onClick={clearAll}>清空画布</button>
//                 <button onClick={extraction}>提取涂鸦的内容</button>
//             </div>
//             {resultSrc && (
//                 <div className="result">
//                 <p>提取结果：</p>
//                 <img src={resultSrc} alt="提取结果" />
//                 </div>
//             )}
//         </div>
//     );
// }