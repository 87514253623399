import { createContext, useContext, useReducer, useEffect } from 'react';
import { message } from 'antd';
import {
    GetUserInfo,
    RefreshUserInfo,
    LogOut
} from '../api/request.js';

const TasksContext = createContext(null);

const TasksDispatchContext = createContext(null);

export function TasksProvider({ children }) {
    const [tasks, dispatch] = useReducer(
        tasksReducer,
        initialTasks
    );
    useEffect(() => {
        const handleResize = () => {
            dispatch({
                type: 'set_window_width',
                width: window.innerWidth
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const delaytime = tasks[3].expires_in ? tasks[3].expires_in * 1000 - 30000 : 10000
        let interval;
        const timeout = setTimeout(() => {
            interval = setInterval(async() => {
                if (tasks[3].userid) {
                    const refreshUserInfo = await RefreshUserInfo({ data: { uid: tasks[3].userid }, token: tasks[3].token })
                    if (refreshUserInfo.code === 1000 && refreshUserInfo.data.userinfo.islogin) {
                        dispatch({
                            type: 'refresh_user_info',
                            data: {
                                userid: refreshUserInfo?.data?.userinfo.userid,
                                name: refreshUserInfo.data.userinfo.name,
                                token: refreshUserInfo.data.tokens.access_token,
                                expires_in: refreshUserInfo.data.tokens.expires_in,
                                email: refreshUserInfo.data.userinfo.email,
                                islogin: Boolean(refreshUserInfo.data.userinfo.islogin)
                            }
                        });
                    } else {
                        message.error('身份验证失败，请重新登录。');
                        dispatch({
                            type: 'log_out'
                        });
                        clearInterval(interval);
                    }
                }
            }, delaytime)
        }, delaytime);
        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks[3].islogin]);

    return (
        <TasksContext.Provider value={tasks}>
            <TasksDispatchContext.Provider value={dispatch}>
                {children}
            </TasksDispatchContext.Provider>
        </TasksContext.Provider>
    );
}

export function useTasks() {
    return useContext(TasksContext);
}

export function useTasksDispatch() {
    return useContext(TasksDispatchContext);
}

function tasksReducer(tasks, action) {
    console.log('action', action)
    switch (action.type) {
        case 'open_login_modal': {
            const newTask = [...tasks];
            newTask[0].isShow = true;
            return newTask;
        }
        case 'cancel_login_modal': {
            const newTask = [...tasks];
            newTask[0].isShow = false;
            return newTask;
        }
        case 'open_signup_modal': {
            const newTask = [...tasks];
            newTask[0].isShow = false;
            newTask[1].isShow = true;
            return newTask;
        }
        case 'cancel_signup_modal': {
            const newTask = [...tasks];
            newTask[1].isShow = false;
            return newTask;
        }
        case 'set_window_width': {
            const newTask = [...tasks];
            newTask[2].windowWidth = action.width;
            return newTask;
        }
        case 'deleted': {
            return tasks.filter(t => t.id !== action.id);
        }
        case 'login': {
            const {
                userid,
                name,
                token,
                expires_in,
                email,
                islogin,
            } = action.data
           
            const newTask = [...tasks];
            localStorage.setItem('login', JSON.stringify({ userid, name, islogin, expires_in, email, token }));
            newTask[3].userid = userid;
            newTask[3].name = name;
            newTask[3].token = token;
            newTask[3].expires_in = expires_in;
            newTask[3].email = email;
            newTask[3].islogin = islogin;
            return newTask;
        }
        case 'refresh_user_info': {
            const { userid, name, islogin, token, expires_in, email } = action.data;
            localStorage.setItem('login', JSON.stringify({ userid, name, islogin, expires_in, email, token }));
            const newTask = [...tasks];
            newTask[3].userid = userid;
            newTask[3].name = name;
            newTask[3].token = token;
            newTask[3].expires_in = expires_in;
            newTask[3].email = email;
            newTask[3].islogin = islogin;
            return newTask;
        }
        case 'log_out': {
            const newTask = [...tasks];
            localStorage.setItem('login', JSON.stringify({}));
            newTask[3].islogin = false;
            newTask[3].userid = '';
            newTask[3].name = '';
            newTask[3].token = '';
            newTask[3].email = '';
            newTask[3].expires_in = 0;
            return newTask;
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

const initialTasks = [
    {
        modalName: 'login',
        isShow: false
    },
    {
        modalName: 'signup',
        isShow: false
    },
    {
        windowWidth: window.innerWidth
    },
    {
        islogin: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).islogin : false,
        userid: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).userid : '',
        name: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).name : '',
        token: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).token : '',
        email: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).email : '',
        expires_in: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')).expires_in : 0,
    }
];