import React, { useState, useEffect } from "react";
import {
    Avatar,
    Radio,
    Space,
    Menu,
    Button,
    Dropdown,
    Modal,
    Divider,
    Input
} from 'antd';
import './index.css';
import google_logo from '../../../images/google_logo.png';
import discord_logo from '../../../images/discord_logo.png';
import wechat_logo from '../../../images/wechat_logo.png';
import read_policy from '../../../images/read_policy.png';
import { useTranslation } from 'react-i18next';
import { useTasks, useTasksDispatch } from '../../../TasksContext';
import { GetUserInfo, RefreshUserInfo } from '../../../api/request.js';

const LoginModal = () => {
    const [readPolicy, setReadPolicy] = useState(false);
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const loginHandleOk = () => {
        dispatch({
            type: 'open_login_modal',
        });
    };
    const loginHandleCancel = () => {
        dispatch({
            type: 'cancel_login_modal',
        });
    };
    const signupClick = () => {
        dispatch({
            type: 'open_signup_modal',
        });
    };
    const impowerClick = async (type) => {
        if (type === 'google') {
            // GetUserInfo({
            //     code: '4998c599-36bf-493c-b2d6-62568f83612d.a16235e7-06d6-4c46-b298-bd9c017c6b52.cf626a9b-c860-449b-a0d8-d0bb358e5866'
            // });
            // RefreshUserInfo({
            //     uid: "7b8311e5-4f58-4691-b4f0-f4ef8f98b004"
            // });
        }
    };
    useEffect(() => {
        
    }, []);

    return (<>
        <Modal
            open={tasks[0].isShow}
            onOk={loginHandleOk}
            onCancel={loginHandleCancel}
            footer={null}
            className="login-modal"
        >
            <div className='login-modal-body'>
                <div className="login-modal-title">{'Sign in to Tensor Ai'}</div>
                <div className="google" onClick={()=> impowerClick('google')}>
                    <img src={google_logo} alt="" />
                    <div>{'Sign in with Google'}</div>
                </div>
                <div className="discord">
                    <img src={discord_logo} alt="" />
                    <div>{'Sign in with Google'}</div>
                </div>
                <div className="wechat">
                    <img src={wechat_logo} alt="" />
                    <div>{'Sign in with Google'}</div>
                </div>
                <Divider
                    style={{
                        borderColor: 'rgba(215, 215, 215, 1)',
                    }}
                >
                    <div className="divider-text">{'or sign in with email'}</div>
                </Divider>
                <div>
                    <div className="email-item">
                        <div className="input-title">
                            <div>{'Email'}</div>
                            <div></div>
                        </div>
                        <Input className="input" placeholder="Enter Email" />
                    </div>
                    <div className="password-item">
                        <div className="input-title">
                            <div>Password</div>
                            <div className="forgot">Forgot?</div>
                        </div>
                        <Input className="input" placeholder="Enter Password" />
                    </div>
                </div>
                <div className="login-but">{'Log in'}</div>
                <div className="to-signup">
                    <div>{"Don't have an account?"}</div>
                    <div className="signup" onClick={signupClick}>{'Sign up'}</div>
                </div>
                <div className="read-policy">
                    {
                        readPolicy ?
                            <img
                                src={read_policy}
                                alt=""
                                className="read"
                                onClick={() => setReadPolicy(false)}
                            /> :
                            <div className="read" onClick={() => setReadPolicy(true)}></div>
                    }
                    <div>{'I have read and agree to the MCSA and Policy'}</div>
                </div>
            </div>
        </Modal>
    </>)
}

export default LoginModal;