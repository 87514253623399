import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { Flex, Image } from 'antd';
import faceid9 from '../../../images/faceid9.png';
import faceid10 from '../../../images/faceid10.png';
import faceid11 from '../../../images/faceid11.png';

const ContentTwo = () => {
    const navigate = useNavigate();

    const handleButtonClick = (props) => {
        return;
        // switch (props) {
        //     case 1:
        //         navigate('/face_change');
        //         break;
        //     case 2:
        //         navigate('/text_style_image');
        //         break;
        //     case 3:
        //         navigate('/text_to_image');
        //         break;
        //     case 4:
        //         navigate('/portrait_master');
        //         break;
        //     case 5:
        //         navigate('/superfraction_repair');
        //         break;
        //     case 6:
        //         navigate('/image_to_image');
        //         break;
        //     case 7:
        //         navigate('/faceId_to_image');
        //         break;
        //     case 8:
        //         navigate('/faceIdLite_to_image');
        //         break;
        //     case 9:
        //         navigate('/styleId_to_image');
        //         break;
        //     default:
        //         navigate('/text_style_image');
        //         break;
        // }
    };

    return (
        <div className='home-content-two'>
            <div className='title'>{'AIphotos'}</div>
            <div className='cont-text'>{'Explore different styles of AI images to express your powerful ideas and connect with your audience'}</div>
            <div className='cont-img'>
                <div className='cont-box'>
                    <img src={faceid9} alt="" />
                    <div>{'· Stylization ·'}</div>
                </div>
                <div className='cont-box'>
                    <img src={faceid10} alt="" />
                    <div>{'· Portrait ·'}</div>
                </div>
                <div className='cont-box'>
                    <img src={faceid11} alt="" />
                    <div>{'· Lamplight ·'}</div>
                </div>
            </div>
        </div>
    );
};

export default ContentTwo;