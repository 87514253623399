import React from 'react';
import './index.css';

const data = {
    h1: 'TensorAI的隐私政策',
    time: '上次更新：2023年10月3日',
    introduce: [
        '本Poe隐私政策（简称“隐私政策”）旨在说明我们在用户使用Poe（一个可让用户与第三方AI模型提供商提供技术支持的机器人进行交流的平台）时会如何处理用户的个人信息。本隐私政策适用于Quora, Inc及其关联公司和子公司（统称“Quora”或“我们”）进行的所有活动，是对《Quora隐私政策》的补充，后者通过引用编入本隐私政策。Quora Inc.是用户个人信息和数据的控制方，负责为用户提供本隐私政策。',
    ],
    content: [
        {
            h2: '我们所收集的信息、使用方式以及处理信息的法律依据',
        },
        {
            h2: '帐户信息。',
            p: [
                '我们会收集用户的电子邮件地址和/或电话号码，用于帐户创建和身份验证。我们还会为Poe帐户创建用户ID。对于现有的Quora用户，我们会使用用户的姓名、Quora关联电子邮件地址、头像和Quora用户ID将其Quora帐户与Poe帐户进行关联。根据用户所在地区的法律规定，我们还可能要求用户提供出生日期以验证其年龄。我们会使用这些帐户信息与用户保持联系、帮助解决问题、以及提供Poe功能更新，以此履行我们对用户的法律或合同义务。'
            ]
        },
        {
            h2: '联系人列表。',
            p: [
                '经用户同意，Poe可能会访问并定期同步其手机上的联系人列表，以便其在Poe上查找和联系朋友。为用户提供个性化体验符合我们的合法商业利益。'
            ]
        },
        {
            h2: '设备信息。',
            p: [
                '我们会收集用户的设备类型和操作系统详细信息，用于分析和解决产品问题，以此履行我们对用户的合同义务。',
            ]
        },
        {
            h2: '第三方应用程序登录。',
            p: [
                '用户可以使用第三方应用程序（例如Google和Apple）登录Poe帐户。此时，我们会收集用户的姓名和该第三方应用程序关联的电子邮件地址，用于身份验证目的，以此履行我们对用户的合同义务。',
            ]
        },
        {
            h2: '订阅计费数据。',
            p: [
                '对于Poe订阅用户，我们会通过第三方服务提供商来收集和处理其帐单信息，例如姓名、帐单地址、信用卡或借记卡详细信息、电话号码和电子邮件地址，以此履行我们对用户的合同义务。',
            ]
        },
        {
            h2: '内容创作者付费。',
            p: [
                '对于Poe的内容创作者，我们会收集他们的电子邮件并与第三方支付处理商共享以便顺利付款。我们还会收集他们的税务信息（例如税号和地址）并与税务服务提供商共享，以此履行我们的法律或合同义务。',
            ]
        },
        {
            h2: '广告。',
            p: [
                '我们会在用户与Poe广告互动时收集用户的相关信息，用以评估广告推广活动的效果。这些信息包括用户的IP地址、广告ID和广告互动情况信息。我们会与广告平台合作伙伴共享经过哈希处理的用户电子邮件，用于我们的付费获客活动。在我们的平台上投放广告符合我们的合法商业利益。必要时，我们会在进行此类处理之前征得用户同意。'
            ]
        },
        {
            h2: '用户研究。',
            p: [
                '我们可能会邀请用户帮助我们测试新功能或参与调查，用以提升Poe的使用体验。用户参与活动完全出于自愿，且所收集的数据会进行匿名化处理。经用户同意，我们可能会使用其个人信息与其联系。改进我们的平台服务符合我们的合法商业利益。'
            ]
        },
        {
            h2: '与Poe上的机器人互动。',
            p: [
                '我们的第三方AI模型提供商和第三方机器人开发者可能会收集用户与Poe上的机器人互动的相关详细信息（包括聊天内容）用以提供和改善其服务，并可能出于合法商业利益考虑处理这些信息。我们不会将用户的Poe帐户信息（例如用户名和电子邮件地址）共享给第三方AI模型提供商或开发者。在Poe上使用API创建机器人的第三方开发者可能会在其服务器上查看和存储经匿名化处理的Poe用户聊天记录，用于其模型训练。我们可能会利用用户在Poe上的互动为用户提供个性化体验，例如推荐使用其他机器人或讨论主题。',
                '请注意，用户在Poe上向机器人提供的任何信息和文件都会共享给其他为机器人提供技术支持的第三方AI模型提供商和开发者，因此没有必要向机器人提供敏感个人信息（例如信用卡信息、社保信息等）。有关第三方AI模型提供商和机器人开发者的更多信息，请参阅相关机器人资料，或访问 Poe隐私中心。'
            ]
        },
        {
            h2: '联系我们。',
            p: [
                '如果您对本隐私政策有任何疑问，请联系我们的数据保护专员(privacy@quora.com)。要详细了解我们的隐私和数据保护实践（包括如何行使您的隐私权），请访问《Quora隐私政策》。'
            ]
        },
    ]
}

const Privacy = () => {
    return (
        <div className='privacy'>
            <h1 className='h1'>{data.h1}</h1>
            {
                data.introduce ?
                    data.introduce.map((item, index) => <p key={index}>{item}</p>) : <></>
            }
            {
                data.content ?
                    data.content.map((item, index) => {
                        return (<div key={index}>
                            <h2 className='h2'>{item.h2}</h2>
                            {
                                item.p ?
                                    item.p.map((childItem, childIndex) => <div key={childIndex}><p>{childItem}</p><br /></div>) : <></>
                            }
                        </div>)
                    }) : <></>
            }
            <i className='i'>{data.time}</i>
        </div>
    );
};

export default Privacy;