import React, { useState, useEffect, useRef } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip,
    Modal
} from 'antd';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    DownloadOutlined,
    QrcodeOutlined,
    EyeOutlined
} from '@ant-design/icons';
import {
    StyleIdAddBgAPI,
} from '../../api/request.js';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import homeicon from '../../images/homeicon.png';
import noimage from '../../images/noimage.png';
import template1 from '../../images/template1.png';
import template2 from '../../images/template2.png';
import template3 from '../../images/template3.png';
import template4 from '../../images/template4.png';
import template5 from '../../images/template5.png';
import template6 from '../../images/template6.png';
import template7 from '../../images/template7.png';
import face1 from '../../images/face1.jpg';
import face2 from '../../images/face2.png';
import face3 from '../../images/face3.jpeg';
import QRCodeModal from './QRcode/index.jsx';
import { useTasks } from '../../TasksContext';
import { useLocation  } from "react-router-dom";


const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const convertURLtoBlob = async (url) => {
    const response = await fetch(url);
    return await response.blob();
};

const StyleIdAddBg = (props) => {
    const { config } = props;
    const tasks = useTasks();
    const myRef = useRef(null);
    const location = useLocation();
    const { category } = location.state || {};
    const [templateImage, setTemplateImage] = useState('xp');
    const [faceImageUrl, setFaceImageUrl] = React.useState('');
    const [form] = Form.useForm();
    const [getImageUrl, setGetImageUrl] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [QROpen, setQROpen] = useState(false);

    const smoothScrollTo = (element) => {
        const top = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo(0, top);
    };

    const handleSubmit = () => {
        if (window.innerWidth < 768) {
            // window.scrollTo(0, 600);
            // myRef.current.scrollIntoView({ behavior: 'smooth' });
            if (myRef.current) {
                smoothScrollTo(myRef.current)
            }
        }
        form.submit();
    };
    const handleFileChange = (template) => {
        setTemplateImage(template);
    };

    const handleFormSubmit = async (values) => {
        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const formData = new FormData();
            for (let i = 0; i < values.source.length; i++) {
                formData.append('sourceImage', values.source[i].originFileObj);
            }
            formData.append('type', 1);
            formData.append('category', 'category_9');
            formData.append('uid', tasks[3].userid);
            // if (templateImage === 'mmc') {
            //     formData.append('scene', 'mmc');
            // } else if (templateImage === 'towers') {
            //     formData.append('scene', 'towers');
            // } else {
            //     formData.append('scene', 'xp');
            // }
            const res = await StyleIdAddBgAPI({formData: formData, token: tasks[3].token, uid: tasks[3].userid});
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('生成失败，可以刷新页面之后重新生成!');
            };
        } catch (error) {
            message.error('生成失败，可以刷新页面之后重新生成!');
        };
        setDisabledSubmit(false);
    };
    const handleDownload = () => {
        const imageUrl = getImageUrl[0];

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleQR = () => {
        setQROpen(true)
    };

    const previewImageRef = useRef(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [portraitLoading, setPortraitLoading] = useState(false);

    const [thumbUrl, setThumbUrl] = useState('');
    const [disabled, setDisabled] = useState(true);
    const draggleRef = useRef(null);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(400);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });
    const handleFaceFileChange = async (e) => {
        setPortraitLoading(true);
        const url = e.target.src;
        const blob = await convertURLtoBlob(url);
        const file = new File([blob], 'image.png', { type: 'image/png' });

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const newFile = {
                uid: new Date(),
                name: 'image.png',
                status: 'done',
                size: file.size,
                type: file.type,
                thumbUrl: reader.result,
                originFileObj: file
            };
            setFileList([newFile]);
            form.setFieldsValue({
                source: [newFile]
            });
            setPortraitLoading(false);
        };
    };
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload =async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('You can only upload image files!');
                return Upload.LIST_IGNORE;
            }
            const isLt2M = file.size / 1024 / 1024 < 6;
            if (!isLt2M) {
                message.error('The image must be less than 6MB!');
                return Upload.LIST_IGNORE;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('Error, you can refresh and try again later!');
            return Upload.LIST_IGNORE;
        }
    };
    const onRemoveClick = () => {
        setPreviewImage('');
        setPreviewOpen(false);
        setPreviewTitle('');
        setFileList([]);
        setGetImageUrl([]);
        setThumbUrl('');
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const onPreviewImage = () => {
        handlePreview(fileList[0]);
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const uploadButton = (
        <button type="button">
            <InboxOutlined />
            <div>点击上传</div>
        </button>
    );
    const handleCancel = () => setPreviewOpen(false);
    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x - targetRect.width/2,
            right: clientWidth - (targetRect.right - uiData.x) + targetRect.width/2,
            top: -targetRect.top + uiData.y - targetRect.height/2,
            bottom: clientHeight - (targetRect.bottom - uiData.y) + targetRect.height/2,
        });
    };
    const handleResize = (_event, { size }) => {
        const { width, height } = size;
        if (width >= 400) {
            setWidth(width);
        } else {
            setWidth(400);
        }
        if (height >= 400) {
            setHeight(height);
        } else {
            setHeight(400);
        }
    };
    useEffect(() => {
        if (previewOpen) {
            setWidth(400);
            setHeight(400);
        }
    }, [previewOpen]);
    useEffect(() => {
        (async () => {
            if (fileList[0]) {
                let thumbUrl;
                if (fileList[0].originFileObj) {
                    thumbUrl = await getBase64(fileList[0].originFileObj);
                } else {
                    thumbUrl = fileList[0].thumbUrl;
                }
                setThumbUrl(thumbUrl);
            }
        })()
    }, [fileList]);




    useEffect(() => {
        window.scrollTo(0, 0);
    }, [config]);

    return (<div className="styleid-add-bg">
        {/* <div className="styleid-add-bg-header">
            <img src={homeicon} alt="" />
            <div><RightOutlined/></div>
            <div>{"Playground"}</div>
            <div><RightOutlined/></div>
            <div>{"FaceID"}</div>
        </div> */}
        <div className="styleid-add-bg-box">
            <div className="prompt">
                <Form
                    form={form}
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                >
                    <div className="title">
                        <span>操作区</span>
                        <Tooltip placement="top" title={'上传图片'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <div className="upload">
                        <div className="title">
                            <span>
                                上传肖像图
                                <span>（单图生成，请尽量选择无遮挡的清晰肖像）</span>
                            </span>
                        </div>
                        <Form.Item
                            name="source"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: 'Please select image' }]}
                            className="upload-item"
                        >
                            <Upload
                                action="/#"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                name="source"
                                maxCount={1}
                                beforeUpload={beforeUpload}
                                className='upload-source'
                                disabled={disabledSubmit}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                        {
                            fileList.length >= 1 ?
                                <div className="upload-action">
                                    <div className="upload-action-img">
                                        <img src={thumbUrl} alt="preview" />
                                    </div>
                                    <div className="upload-action-but">
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={onPreviewImage}
                                            disabled={disabledSubmit}
                                        >
                                            <EyeOutlined />
                                            preview
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={onRemoveClick}
                                            disabled={disabledSubmit}
                                        >
                                            <DeleteOutlined />
                                            delete
                                        </Button>
                                    </div>
                                </div> : <></>
                        }
                        <div className="exa-img">
                            <img src={face1} alt="" onClick={disabledSubmit ? () => {} : handleFaceFileChange}/>
                            <img src={face2} alt="" onClick={disabledSubmit ? () => {} : handleFaceFileChange}/>
                            <img src={face3} alt="" onClick={disabledSubmit ? () => {} : handleFaceFileChange}/>
                        </div>
                        <Modal
                            open={previewOpen}
                            title={
                                <div
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                        cursor: 'move',
                                    }}
                                    onMouseOver={() => {
                                        if (disabled) {
                                            setDisabled(false);
                                        }
                                    }}
                                    onMouseOut={() => {
                                        setDisabled(true);
                                    }}
                                >
                                    {previewTitle}
                                    <div
                                        style={{
                                            width: previewImageRef?.current?.offsetWidth || 400,
                                            height: previewImageRef?.current?.offsetHeight || 400,
                                            position: 'absolute',
                                            top: '32px',
                                            right: 0,
                                            zIndex: 999
                                        }}
                                    >
                                    </div>
                                </div>
                            }
                            footer={null}
                            onCancel={handleCancel}
                            mask={false}
                            modalRender={(modal) => (
                                <Draggable
                                    disabled={disabled}
                                    bounds={bounds}
                                    nodeRef={draggleRef}
                                    onStart={(event, uiData) => onStart(event, uiData)}
                                >
                                    <div ref={draggleRef}>{modal}</div>
                                </Draggable>
                            )}
                            width={width}
                            className="preview"
                        >
                            <Resizable
                                width={width}
                                height={height}
                                onResize={handleResize}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                >
                                    <img
                                        alt="example"
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                            verticalAlign: 'middle',
                                            userDrag: 'none',
                                            WebkitUserDrag: 'none',
                                            userSelect: 'none',
                                            WebkitUserSelect: 'none',
                                            MozUserSelect: 'none',
                                            MsUserSelect: 'none'
                                        }}
                                        src={previewImage}
                                        ref={previewImageRef}
                                    />
                                </div>
                            </Resizable>
                        </Modal>
                        <div className="title">
                            <span>场景示意图</span>
                        </div>
                        <img
                            src={
                                templateImage === 'mmc' ?
                                    template2 :
                                    templateImage === 'xp' ?
                                    template1 : template3
                            }
                            alt=""
                            className="template-image"
                        />
                        <div className="portraits">{'( 所有场景和人像均由TensorAI生成，共3个场景，场景内图片随机生成，以下照片为示意图模版 )'}</div>
                        <div className="exa-img">
                            <img
                                src={template1}
                                alt=""
                                onClick={() => handleFileChange('xp')}
                                style={{
                                    opacity: templateImage === 'xp' ? 1 : 0.5
                                }}
                            />
                            <img
                                src={template2}
                                alt=""
                                onClick={() => handleFileChange('mmc')}
                                style={{
                                    opacity: templateImage === 'mmc' ? 1 : 0.5
                                }}
                            />
                            <img
                                src={template3}
                                alt=""
                                onClick={() => handleFileChange('towers')}
                                style={{
                                    opacity: templateImage === 'towers' ? 1 : 0.5
                                }}
                            />
                        </div>
                    </div>
                </Form>
              
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    生成
                </Button>
            </div>
            <div className="output-image">
                <div className="output-image-bfc" ref={myRef}>
                    {
                        getImageUrl.length > 0 ?
                            <Image.PreviewGroup items={getImageUrl}>
                                <Image src={getImageUrl[0]} className="img"/>
                            </Image.PreviewGroup>  :
                            disabledSubmit ? <></> :
                            <img className="noimage" src={noimage} alt="" />
                    }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >正在生成，请稍后...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl.length > 0 ?
                        <div className="qr-but">
                            <Button
                                onClick={handleDownload}
                                type="primary"
                                icon={<DownloadOutlined />}
                                className="but"
                            >
                                下载
                            </Button>
                            <Button
                                onClick={handleQR}
                                type="primary"
                                className="qr"
                            >
                                <QrcodeOutlined />
                            </Button>
                        </div>
                         : <></>
                }
                <QRCodeModal QROpen={QROpen} setQROpen={setQROpen} getImageUrl={getImageUrl[0]} />
            </div>
        </div>
        <div className="styleid-add-bg-footer" style={{ marginTop: window.innerWidth > 768 ? (getImageUrl.length > 0 ? "15.7rem" : "5.7rem") : (getImageUrl.length > 0 ? '92px' : '30px') }}>
            <div className="footer-title">Tensor AI</div>
            <div className="footer-content">{'我们专注AI模型开发和优化部署，致力于成为AIGC视觉方案中的领军者，通过创新和可靠的解决方案，与合作伙伴一起打造视觉上的无限可能。'}</div>
        </div>
        <div className="styleid-add-bg-image-list">
            <img src={template4} alt="" />
            <img src={template5} alt="" />
            <img src={template6} alt="" />
            <img src={template7} alt="" />
        </div>
    </div>
    )
};
export default StyleIdAddBg;