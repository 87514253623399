import React, { useState } from "react";
import {
    Avatar,
    Radio,
    Space,
    Menu,
    Button,
    Dropdown,
    Modal,
    Divider,
    Input
} from 'antd';
import './index.css';
import google_logo from '../../../images/google_logo.png';
import discord_logo from '../../../images/discord_logo.png';
import wechat_logo from '../../../images/wechat_logo.png';
import read_policy from '../../../images/read_policy.png';
import { useTranslation } from 'react-i18next';
import { useTasks, useTasksDispatch } from '../../../TasksContext';

const SignupModal = () => {
    const [readPolicy, setReadPolicy] = useState(false);
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const loginHandleOk = () => {
        dispatch({
            type: 'open_signup_modal',
        });
    }
    const loginHandleCancel = () => {
        dispatch({
            type: 'cancel_signup_modal',
        });
    }

    return (<>
        <Modal
            open={tasks[1].isShow}
            onOk={loginHandleOk}
            onCancel={loginHandleCancel}
            footer={null}
            className="signup-modal"
        >
            <div className='signup-modal-body'>
                <div className="signup-modal-title">{'Sign up to Tensor Ai'}</div>
                <div>
                    <div className="email-item">
                        <div className="input-title">
                            <div>{'Email'}</div>
                            <div></div>
                        </div>
                        <Input className="input" placeholder="Enter Email" />
                    </div>
                    <div className="password-item">
                        <div className="input-title">
                            <div>Password</div>
                        </div>
                        <Input className="input" placeholder="Enter Password" />
                    </div>
                    <div className="password-item">
                        <div className="input-title">
                            <div>Password</div>
                        </div>
                        <Input className="input" placeholder="Enter Password" />
                    </div>
                    <div className="auth-code">
                        <div className="input-title">
                            <div>Enter verification code</div>
                        </div>
                        <div className="auth-code-action">
                            <Input className="input" placeholder="Enter verification code" />
                            <Button className="but">Obtain</Button>
                        </div>
                    </div>
                </div>
                <div className="signup-but">{'Sign in'}</div>
            </div>
        </Modal>
    </>)
}

export default SignupModal;