import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, Slider } from 'antd';
import './index.css';

const EditImage = (props) => {
    const {
        isModalOpen,
        setIsModalOpen,
        imageUrl,
        drawingHistory,
        setDrawingHistory,
        resultSrc,
        setResultSrc,
        setResultSpritesSrc,
        setEditImageShow
    } = props;
    const imgRef = useRef(null);
    const canvasRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const [brushWidth, setBrushWidth] = useState(5);
  
    const startDrawing = (event) => {
        setDrawing(true);
        const ctx = canvasRef.current.getContext('2d');
        ctx.beginPath();

        const { offsetX, offsetY } = event.nativeEvent;
        ctx.moveTo(offsetX, offsetY);
    };

    const draw = (event) => {
        if (!drawing) return;

        const ctx = canvasRef.current.getContext('2d');
        ctx.lineWidth = brushWidth;
        ctx.strokeStyle = 'white';
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.lineCap = 'round';
        
        const { offsetX, offsetY } = event.nativeEvent;
        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();
    };

    const stopDrawing = () => {
        setDrawing(false);

        const ctx = canvasRef.current.getContext('2d');
        ctx.closePath();
        setDrawingHistory([...drawingHistory, canvasRef.current.toDataURL()]);
    };

    const canvasToImage = () => {
        const newCanvas = document.createElement('canvas');
        const newContext = newCanvas.getContext('2d');
        newCanvas.width = canvasRef.current.width;
        newCanvas.height = canvasRef.current.height;

        newContext.fillStyle = 'black';
        newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
        newContext.drawImage(canvasRef.current, 0, 0);

        return newCanvas.toDataURL('image/png');
    };
    const canvasSpritesToImage = () => {
        const newCanvas = document.createElement('canvas');
        const newContext = newCanvas.getContext('2d');
        newCanvas.width = canvasRef.current.width;
        newCanvas.height = canvasRef.current.height;

        newContext.clearRect(0, 0, newCanvas.width, newCanvas.height);
        newContext.drawImage(canvasRef.current, 0, 0);

        return newCanvas.toDataURL('image/png');
    };

    const undoLastDraw = () => {
        if (drawingHistory.length > 0) {
            const newHistory = drawingHistory.slice(0, -1);
            setDrawingHistory(newHistory);
            clearCanvas();
            redrawHistory(newHistory);
        }
    };

    const clearCanvas = () => {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const redrawHistory = (history) => {

        const ctx = canvasRef.current.getContext('2d');
        const dataURL = history[history.length - 1];
        if (dataURL) {
            const img = new Image();
            img.src = dataURL;

            img.onload = () => {
                ctx.drawImage(img, 0, 0);
            };
        }
    };

    const clearAll = () => {
        setDrawingHistory([]);
        clearCanvas();
    };

    const extraction = () => {
        setResultSrc(canvasToImage());
        setResultSpritesSrc(canvasSpritesToImage())
    };

    useEffect(() => {
        const setCanvas = () => {
            if (canvasRef.current && imgRef.current) {
                canvasRef.current.width =  imgRef.current.naturalWidth;
                canvasRef.current.height =  imgRef.current.naturalHeight;

                var elementImage = document.querySelector('.mymodal .main .image');

                elementImage.style.width = imgRef.current.naturalWidth + 'px' ;
                elementImage.style.height = imgRef.current.naturalHeight + 'px' ;
            }
        };
        if (canvasRef && imgRef) {
            setCanvas();
            if (drawingHistory) {
                redrawHistory(drawingHistory)
            }
        }
    }, []);

    const handleKeyDown = (event) => {
        if (event.keyCode === 219) {
            setBrushWidth( prevWidth => prevWidth - 1 < 1 ? 1 : prevWidth - 1);
        } else if (event.keyCode === 221) {
            setBrushWidth( prevWidth => prevWidth + 1 > 50 ? 50 : prevWidth + 1);
        }
    }
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const onChange = (value) => {
        setBrushWidth(value);
        const ctx = canvasRef.current.getContext('2d');
        ctx.lineWidth = value;
    };


    const handleOk = () => {
        extraction();
        setIsModalOpen(false);
        setEditImageShow(false);
    };
  
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditImageShow(false);
    };

    return (
        <Modal
            closable={false}
            maskClosable={false}
            centered={true}
            destroyOnClose={true}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            )}
            width={'100%'}
            className='mymodal'
            title="你可以在下图中做任意涂鸦："
        >
            <div>
                <div className="main">
                    <div className="image" onContextMenu={(e) => e.preventDefault()}>
                        <img ref={imgRef} src={imageUrl} alt="可涂鸦的图像" />
                        <canvas
                            ref={canvasRef}
                            onMouseDown={startDrawing}
                            onMouseMove={draw}
                            onMouseUp={stopDrawing}
                            style={{ cursor: 'crosshair' }}
                            // style={{ cursor: `url('${next}') 10 10, crosshair` }}
                            // style={{ cursor: `url(../../../images/next.cur) 10 10, crosshair` }}
                            // style={{
                            //     cursor: 'url(path/to/cursor.cur), auto',
                            //     width: '30px', // 光标宽度
                            //     height: '30px', // 光标高度
                            //   }}
                        />
                    </div>
                </div>
                <div className="actions">
                    <Button onClick={undoLastDraw}>撤回</Button>
                    <Button onClick={clearAll}>清空画布</Button>
                    {/* <button onClick={extraction}>提取涂鸦的内容</button> */}
                    <div>画笔粗细：</div>
                    <Slider defaultValue={5} value={brushWidth} onChange={onChange} max={50} min={1} marks={1} keyboard={false}/>
                </div>
                {/* {resultSrc && (
                    <div className="result">
                    <p>提取结果：</p>
                    <img src={resultSrc} alt="提取结果" />
                    </div>
                )} */}
            </div>
        </Modal>
    )
}

export default EditImage;