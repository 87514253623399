import React, { useState } from 'react';
import {
    SearchOutlined
} from '@ant-design/icons';
import {
    Table,
    Space,
    ConfigProvider,
    Button
} from 'antd';
import './index.css';
import none_data_two from '../../../images/none_data_two.png';

const columns = [
    {
        title: 'ORDER UNMBER',
        dataIndex: 'token',
        key: 'token',
    },
    {
        title: 'TIME',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'VIP',
        dataIndex: 'vip',
        key: 'vip',
    },
    {
        title: 'MONEY',
        key: 'money',
        dataIndex: 'money'
    },
    {
        title: 'PAYMENT METHOD',
        dataIndex: 'payment_method',
        key: 'payment_method',
    },
    {
        title: 'INVOICE',
        dataIndex: 'invoice',
        key: 'invoice',
        render: () => (<Button type="link" style={{ paddingLeft: 0 }}>{"Download"}</Button>)
    },
    {
        title: 'AUTOMATIC RENEWALS',
        dataIndex: 'renew',
        key: 'renew',
        render: () => (<Button  shape="round">{"Turn off"}</Button>)
    },
];
const data = [
    {
        key: '1',
        token: 'abcd12345678',
        time: '2024-09-10 16:34:47',
        vip: 'Standard',
        money: '$23.90',
        payment_method: 'Card',
        invoice: 'Download',
        renew: false,
    },
    {
        key: '2',
        token: 'abcd12345678',
        time: '2024-09-10 16:34:47',
        vip: 'Standard',
        money: '$23.90',
        payment_method: 'Card',
        invoice: 'Download',
        renew: false,
    },
    {
        key: '3',
        token: 'abcd12345678',
        time: '2024-09-10 16:34:47',
        vip: 'Standard',
        money: '$23.90',
        payment_method: 'Card',
        invoice: 'Download',
        renew: false,
    }
    
];

const RechargeRecord = () => {

    const emptyContent = (
        <div className='none-data'>
            <img
                src={none_data_two}
                alt="No Data"
            />
            <div>{'No recharge records available at the moment'}</div>
        </div>
    );

    return <div className='recharge-record'>
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: '#F0F0F0',
                        headerColor: 'rgba(0, 0, 0, 1)',
                        colorText: 'rgba(113, 128, 150, 1)',
                    },
                },
            }}
        >
            <Table
                columns={columns}
                dataSource={data}
                locale={{ emptyText: emptyContent }}
            />
        </ConfigProvider>
    </div>
};

export default RechargeRecord;