import React, { useState } from 'react';
import {
    Button,
    Divider,
    Input,
    Modal
} from 'antd';
import './index.css';
import pricing_item_bg from '../../images/pricing_item_bg.png';
import integral_icon from '../../images/integral_icon.png';
import white_star from '../../images/white_star.png';
import black_star from '../../images/black_star.png';

const Pricing = () => {
    const [ integralValue, setIntegralValue ] = useState(100);
    const [isShowInstructions, setIsShowInstructions] = useState(false);

    const showInstructions = () => {
        setIsShowInstructions(true);
    };
    const handleOk = () => {
        setIsShowInstructions(false);
    };
    const handleCancel = () => {
        setIsShowInstructions(false);
    };

    const integralOnChange = (e) => {
        if (e.target.value < 100) {
            setIntegralValue(100);
        } else if (e.target.value > 1000000) {
            setIntegralValue(1000000);
        } else {
            setIntegralValue(e.target.value);
        } 
    }

    return (
        <div className='box-pricing'>
            <div className='pricing'>
                <div className='title'>{'Plans and pricing'}</div>
                <div className='cont-text'>
                    <div>{'Choose the perfect package'}</div>
                    <div>{'and start creating stunning photos instantly'}</div>
                </div>
                <div className='explain-but'>
                    <Button type="link" onClick={showInstructions}>
                        {'《Recharge instructions》'}
                    </Button>
                </div>
                <Modal
                    open={isShowInstructions}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <div className='instructions-modal'>
                        <div>{'Recharge Instructions'}</div>
                        <p>Some contents...Some contents...Some contents...Some contents...Some contents...Some contents...Some contents...</p>
                        <p>Some contents...</p>
                        <p>Some contents...</p>
                    </div>
                </Modal>
                <div className='subscribe-box'>
                    <div className='subscribe-one'>
                        <div className='subscribe-one-title'>{'STANDARD'}</div>
                        <div
                            className='subscribe-one-cont'
                            style={{
                                background: `url(${pricing_item_bg})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <div className='integral'>
                                <img src={integral_icon} alt="" />
                                <div>{'2700 + 500'}</div>
                            </div>
                            <div className='money'>{'$28.80'}</div>
                            <Button
                                type="primary"
                                shape="round"
                                className='but'
                            >
                                {'Buy Now'}
                            </Button>
                        </div>
                    </div>
                    <div className='subscribe-two'>
                        <div className='subscribe-two-title'>
                            <img src={black_star} alt="" />
                            <div>{'PLUS'}</div>
                        </div>
                        <div
                            className='subscribe-two-cont'
                            style={{
                                background: `url(${pricing_item_bg})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <div className='integral'>
                                <img src={integral_icon} alt="" />
                                <div>{'2700 + 500'}</div>
                            </div>
                            <div className='money'>{'$28.80'}</div>
                            <Button
                                type="primary"
                                shape="round"
                                className='but'
                            >
                                {'Buy Now'}
                            </Button>
                        </div>
                    </div>
                    <div className='subscribe-three'>
                        <div className='subscribe-three-title'>
                            <img src={white_star} alt="" />
                            <div>{'PRO'}</div>
                        </div>
                        <div
                            className='subscribe-three-cont'
                            style={{
                                background: `url(${pricing_item_bg})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <div className='integral'>
                                <img src={integral_icon} alt="" />
                                <div>{'2700 + 500'}</div>
                            </div>
                            <div className='money'>{'$28.80'}</div>
                            <Button
                                type="primary"
                                shape="round"
                                className='but'
                            >
                                {'Buy Now'}
                            </Button>
                        </div>
                    </div>
                </div>
                <Divider
                    dashed={true}
                    className='divider'
                >{"Or purchase separately"}</Divider>
                <div className='single-box'>
                    <div className='subscribe-single'>
                        <div className='subscribe-single-title'>{'PURCHASE SEPARATELY'}</div>
                        <div
                            className='subscribe-single-cont'
                            style={{
                                background: `url(${pricing_item_bg})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}
                        >
                            <div className='integral'>
                                <img src={integral_icon} alt="" />
                                <Input
                                    className='input'
                                    type="number"
                                    min="100" 
                                    max="1000000" 
                                    step="100" 
                                    value={integralValue}
                                    onChange={integralOnChange}
                                />
                            </div>
                            <div className='money'>{'$' + (integralValue / 100 * 28.80).toFixed(2)}</div>
                            <Button
                                type="primary"
                                shape="round"
                                className='but'
                            >
                                {'Buy Now'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;