import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Skeleton,
    Modal,
    Image,
    Upload,
    Carousel,
    message
} from 'antd';
import {
    PlusOutlined
} from '@ant-design/icons';
import { useNavigate  } from "react-router-dom";
import './index.css';
import hot from '../../../images/hot.png';
import integral_icon from '../../../images/integral_icon.png';
import { useTasks } from '../../../TasksContext';

import { StyleIdAddBgAPI } from '../../../api/request.js';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function dataURLtoBlob(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new Blob([u8arr], {type: mime});
}

const FlexBox = (props) => {
    const { sceneList, setSceneList, module } = props;
    const navigate = useNavigate();
    const carouselRef = useRef(null);
    const tasks = useTasks();
    const [openActionModal, setOpenActionModal] = useState(false);
    const [actionModalData, setActionModalData] = useState({})
    const [type, setType] = useState(1);

    const handleCancel = () => {
        setOpenActionModal(false);
        setActionModalData({});
    };

    // const handleLoad = (index) => {
    //     // console.log(index);
    //     setSceneList((prevStatus) => {
    //         const newStatus = [...prevStatus];
    //         newStatus[index] = {
    //             ...newStatus[index],
    //             loaded: true,
    //             error: false
    //         };
    //         return newStatus;
    //     });
    // };

    // const handleError = (index) => {
    //     setSceneList((prevStatus) => {
    //         const newStatus = [...prevStatus];
    //         newStatus[index] = {
    //             ...newStatus[index],
    //             loaded: false,
    //             error: true
    //         };
    //         return newStatus;
    //     });
    // };

    const generateClick = (item) => {
        setActionModalData(item);
        setOpenActionModal(true);
        // BurialPointReport();
        // window.gtag('event', 'button_click', {
        //     'event_category': category,
        // });
        // window.gtag('event', 'button_click', {
        //     'event_category': category,
        //     'Click_scene': 'Click_scene'
        // });
        window.gtag('event', 'button_click', {
            'event_category': item.category,
            'Click_scene': 'event_category'
        });
        // navigate('/styleId_add_bg', { state: { category: category } });
        // window.gtag('event', 'button_click', {
        //     'event_category': '按钮',
        //     'event_label': '点击'
        // });
    }


    const imgClick = (item) => {
        setType(item.type);
        if (carouselRef.current) {
            carouselRef.current.goTo(item.type === 1 ? 0 : 1)
        }
    };


    //上传图片
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        } 
        setPreviewImage(file.url || file.preview);
        if (!file.one) {
            setPreviewOpen(true);
        }
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (newFileList.length > 0) {
            const reader = new FileReader();
            reader.onload = function(event) {
                const base64String = event.target.result;
                localStorage.setItem('useImage', base64String);
            };
            reader.readAsDataURL(newFileList[0].originFileObj);
        } else {
            localStorage.setItem('useImage', '');
        }
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const generateActionClick = async () => {
        try {
            const formData = new FormData();
            for (let i = 0; i < fileList.length; i++) {
                formData.append('sourceImage', fileList[i].originFileObj);
            }
            formData.append('type', type);
            formData.append('category', actionModalData.category);
            formData.append('uid', tasks[3].userid);
        
            const res = await StyleIdAddBgAPI({formData: formData, token: tasks[3].token, uid: tasks[3].userid});
            if (res.data.url && res.code === 1000) {
                console.log(res.data.url);
            } else {
                message.error('生成失败');
            };
        } catch (error) {
            message.error('生成失败');
        };
    }
    useEffect(() => {
        if (localStorage.getItem('useImage')) {
            const base64String = localStorage.getItem('useImage');
            if (base64String) {
                const blob = dataURLtoBlob(base64String);
                const file = new File([blob], "filename.png", { type: 'image/png' });
                setFileList([{
                    originFileObj: file,
                }]);
                handlePreview({
                    originFileObj: file,
                    one: 1
                });
            }
        }
    }, []);

    return <div className="list-flex-box" key={module}>
        {
            sceneList.length > 0 ? sceneList.map((item, index) => <div className="list-flex-box-item" key={item.category}>
                <img
                    src={item.scenes[0].imageurl}
                    alt=""
                    // onLoad={() => handleLoad(index)}
                    // onError={() => handleError(index)}
                    className="list-item-img"
                    // style={{
                    //     display: item.loaded ? 'block' : 'none'
                    // }}
                />
                {/* {
                    !item.loaded ? <Skeleton.Image active={true} className="list-item-img"/> : <></>
                } */}
                <div className="list-item-box">
                    <div className="list-item-styler">
                        <div>{item.category}</div>
                        {
                            item.hot ? <img src={hot} alt="" /> : <></>
                        }
                    </div>
                    <div className="list-item-action">
                        <div className="list-item-integral">
                            <img src={integral_icon} alt="" />
                            <div>{item.integral}</div>
                        </div>
                        <Button type="primary" shape="round" className='but' onClick={() => generateClick(item)}>
                            {'Generate'}
                        </Button>
                    </div>
                </div>
            </div>) : <></>
        }
        <Modal
            open={openActionModal}
            onCancel={handleCancel}
            footer={null}
            className="generate-modal"
            width={"60%"}
            centered={true}
        >
           <div className="box">
                <div className="carousel">
                    <Carousel effect="fade" ref={carouselRef}>
                        {Object.keys(actionModalData).length !== 0 ? actionModalData.scenes.map((img, index) => (
                            <img
                                key={img.type}
                                src={img.imageurl}
                                alt={`Thumbnail ${index + 1}`}
                                className="img"
                            />
                        )) : <></>}
                    </Carousel>
                    <div className="flex-action">
                        {Object.keys(actionModalData).length !== 0 ? actionModalData.scenes.map((img, index) => (
                            <img
                                key={index}
                                src={img.imageurl}
                                alt={`Thumbnail ${index + 1}`}
                                onClick={() => imgClick(img)}
                                className={ `img ${ img.type ===  type ? 'select-img' : ''}`}
                            />
                        )) : <></>}
                    </div>
                </div>
                <div className="select">
                    <div className="title">{Object.keys(actionModalData).length !== 0 ? actionModalData.category : ''}</div>
                    <Upload
                        beforeUpload={() => false}
                        onChange={handleChange}
                        className="upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                    >
                        {fileList.length > 0 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                        <Image
                            wrapperStyle={{
                                display: 'none',
                            }}
                            preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                        />
                    )}
                    <div className="price">
                        <p>Price: 300</p>
                        <p>Views: 17879868</p>
                    </div>
                    <Button type="primary" onClick={generateActionClick} className="but">
                        Generate
                    </Button>
                </div>
            </div>
        </Modal>
    </div>
}

export default FlexBox;