import React, { useState, useEffect } from 'react';
import {
    SearchOutlined
} from '@ant-design/icons';
import {
    Menu,
    Input,
    Button,
    Skeleton,
    Pagination,
    Image
} from 'antd';
import './index.css';
import list_item1 from '../../../images/list_item1.jpg';
import list_item2 from '../../../images/list_item2.jpg';
import list_item3 from '../../../images/list_item3.jpg';
import list_item4 from '../../../images/list_item4.jpg';
import list_item5 from '../../../images/list_item5.jpg';
import list_item6 from '../../../images/list_item6.jpg';
import list_item7 from '../../../images/list_item7.jpg';
import list_item8 from '../../../images/list_item8.jpg';
import download from '../../../images/download.png';
import check from '../../../images/check.png';
import none_data_one from '../../../images/none_data_one.png';
import { GetImagesHistory } from '../../../api/request.js';
import { useTasks } from '../../../TasksContext/index.js';


const items = [
    {
        label: 'All Models',
        key: 'all',
    },
    {
        label: 'Text To Image',
        key: 'text',
    },
    {
        label: 'Image To Image',
        key: 'image',
    }
   
];

const listData = [
    {
        img: list_item1,
        styler: '50s',
        hot: true,
        integral: 0,
        isDelete: false
    },
    {
        img: list_item2,
        styler: '50s',
        hot: true,
        integral: 200,
        isDelete: false
    },
    {
        img: list_item3,
        styler: '50s',
        hot: true,
        integral: 200,
        isDelete: false
    },
    {
        img: list_item4,
        styler: '50s',
        hot: true,
        integral: 100,
        isDelete: false
    },
    {
        img: list_item5,
        styler: '50s',
        hot: true,
        integral: 999,
        isDelete: false
    },
    {
        img: list_item6,
        styler: '50s',
        hot: true,
        integral: 400,
        isDelete: false
    },
    {
        img: list_item7,
        styler: '50s',
        hot: true,
        integral: 300,
        isDelete: false
    },
    {
        img: list_item8,
        styler: '50s',
        hot: true,
        integral: 200,
        isDelete: false
    }
]

const MyArtSpace = () => {
    const tasks = useTasks();
    const [current, setCurrent] = useState('all');
    const [isEdit, setIsEdit] = useState(false);
    const [renderListData, setRenderListData] = useState([]);

    const [loadStatus, setLoadStatus] = useState([]);

    const handleLoad = (index) => {
        console.log(index);
        setLoadStatus((prevStatus) => {
            const newStatus = [...prevStatus];
            newStatus[index] = { loaded: true, error: false };
            return newStatus;
        });
    };

    const handleError = (index) => {
        setLoadStatus((prevStatus) => {
            const newStatus = [...prevStatus];
            newStatus[index] = { loaded: false, error: true };
            return newStatus;
        });
    };

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const editClick = () => {
        setIsEdit(true);
    };

    const cancelOnClick = (index) => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            newRenderListData[index].isDelete = false;
            return newRenderListData;
        });
    };
    const cancelDeleteOnClick = (index) => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            newRenderListData[index].isDelete = true;
            return newRenderListData;
        });
    };
    const allDelete = () => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            for (let i = 0; i < newRenderListData.length; i++) {
                newRenderListData[i].isDelete = true;
            }
            return newRenderListData;
        });
    };
    const cancelDelete = () => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            for (let i = 0; i < newRenderListData.length; i++) {
                newRenderListData[i].isDelete = false;
            }
            return newRenderListData;
        });
        setIsEdit(false)
    };

    useEffect(() => {
        (async() => {
            const getScenesList = await GetImagesHistory({ data: { uid: tasks[3].userid },  token: tasks[3].token });

            if (getScenesList.code ===  1000) {
                const newList = JSON.parse(JSON.stringify(getScenesList.data));
                for (let i = 0; i < newList.length; i++) {
                    newList[i] = {
                        ...newList[i],
                        loaded: false,
                        error: false
                    };
                }
                setLoadStatus(newList);
            }
        })()
    }, [tasks]);

    return <div className="artspace">
        <div className="title">
            <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
                className='menu'
            />
            <div className='title-but'>
                <Input
                    size="large"
                    placeholder="large size"
                    prefix={<SearchOutlined />}
                    className='input'
                />
                {
                    isEdit ?
                        <div className='delete-box'>
                            <Button type="link">Delete</Button>
                            <Button type="link" onClick={allDelete}>All</Button>
                            <Button type="link" onClick={cancelDelete}>Cancel</Button>
                        </div> :
                        <Button onClick={editClick} className='but'>Edit</Button>
                }
            </div>
        </div>
        <div className='content'>
            {
                loadStatus ? loadStatus.map((item, index) => <div className="content-item" key={index}>
                    {/* <img
                        src={item.img}
                        alt=""
                        onLoad={() => handleLoad(index)}
                        onError={() => handleError(index)}
                        className="content-item-img"
                        style={{
                            display: loadStatus[index].loaded ? 'block' : 'none'
                        }}
                    /> */}
                    <Image.PreviewGroup
                        items={item.url}
                    >
                        <Image
                            src={item.url[0]}
                            className="content-item-img"
                        />
                    </Image.PreviewGroup>
                    {/* {
                        !loadStatus[index].loaded ? <Skeleton.Image active={true} className="content-item-img"/> : <></>
                    } */}
                    <div className="content-item-box">
                        <div>{item.category}</div>
                        {
                            item.hot ? <img src={download} alt="" /> : <></>
                        }
                    </div>
                    {
                        isEdit ?
                            <div className='edit-mask'>
                                {
                                    item.isDelete ?
                                    <img
                                        src={check}
                                        alt=""
                                        className='check'
                                        onClick={() => cancelOnClick(index)}
                                    /> :
                                    <div
                                        className='check'
                                        onClick={() => cancelDeleteOnClick(index)}
                                    ></div>
                                }
                            </div> : <></>
                    }
                </div>) : <div className='none-data'>
                    <img src={none_data_one} alt="" />
                    <div>{'No picture available at the moment'}</div>
                </div>
            }
        </div>
        {
            renderListData.length > 0 ?
            <Pagination
                defaultCurrent={1}
                total={50}
                className='sorter'
            /> : <></>
        }
    </div>
};

export default MyArtSpace;