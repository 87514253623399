import React, { useRef } from 'react';
import {
    Button,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.css';
import playground1 from '../../../images/playground1.png';
import playground2 from '../../../images/playground2.png';

const ContentThree = () => {
    const navigate = useNavigate();

    return (
        <div className='home-content-three'>
            <div className='title'>{'Play Ground'}</div>
            <div className='cont-text'>{'Explore the AI tools and features that will bring out the maestro in you, no matter your skills'}</div>
            <div className='cont-example'>
                <div className='cont-example-box'>
                    <div className='example-box'>
                        <img src={playground1} alt="playground1" />
                        <div>{'Picture Generate Picture'}</div>
                    </div>
                    <Button type="primary" shape="round" className='but'>
                        {'Generate'}
                    </Button>
                </div>
                <div className='cont-example-box'>
                    <div className='example-box'>
                        <img src={playground2} alt="playground2" />
                        <div>{'Text Generate Picture'}</div>
                    </div>
                    <Button type="primary" shape="round" className='but'>
                        {'Generate'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ContentThree;