const en = {
    home: {
        title: "Create beautiful art with Ariificial Intelligence"
    },
    header: {
        home: 'Home',
        list: 'List',
        pricing: 'Pricing',
        contactus: 'Contact Us',
    }
};
export default en;